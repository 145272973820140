import React from "react";


const NotFoundPage = () => (
 
  <>


      <h1>NOT FOUND</h1>
      <p>Page does not exist.</p>
      </>

);

export default NotFoundPage;
